var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c("h1", [
                _vm._v(
                  _vm._s(_vm.$t("Album")) +
                    ": " +
                    _vm._s(_vm.release.ProductDetails.Title) +
                    " - " +
                    _vm._s(_vm.release.ProductDetails.Attribution)
                )
              ]),
              _vm.maintenance
                ? _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "This option is currently unavailable due to maintenance."
                        )
                      )
                    )
                  ])
                : _vm._e(),
              _c(
                "b-alert",
                {
                  attrs: { show: _vm.errorMessage !== null, variant: "danger" }
                },
                [_vm._v(" " + _vm._s(_vm.errorMessage) + " ")]
              ),
              _vm.maintenance
                ? _c(
                    "router-link",
                    {
                      staticClass: "ml-2",
                      attrs: { to: { name: "release-detail" }, tag: "b-button" }
                    },
                    [_vm._v(_vm._s(_vm.$t("Back")))]
                  )
                : _vm._e(),
              false
                ? _c("page-snippet", { attrs: { name: "portal-update" } })
                : _vm._e(),
              (!_vm.errorMessage && !_vm.maintenance) ||
              _vm.$root.$data.me.EMail == "mail@erikbas.nl"
                ? _c(
                    "b-card",
                    {
                      staticClass: "mt-4",
                      attrs: { header: _vm.$t("Portals") }
                    },
                    [
                      _vm.releaseOutlets == null
                        ? _c("b-spinner", { attrs: { label: "Spinning" } })
                        : _vm._e(),
                      _vm.releaseOutlets != null
                        ? _c("b-table-simple", { attrs: { bordered: "" } }, [
                            _c(
                              "tbody",
                              [
                                _vm._l(_vm.outlets, function(outlet, index) {
                                  return [
                                    _c("tr", { key: "a" + index }, [
                                      _c(
                                        "td",
                                        { staticClass: "outlet-image" },
                                        [
                                          outlet.ImageUrl
                                            ? _c("img", {
                                                class: {
                                                  "portal-logo": true,
                                                  disabled: !_vm.selectedOptions.includes(
                                                    outlet.JsonValue
                                                  )
                                                },
                                                attrs: {
                                                  src: outlet.ImageUrl,
                                                  alt: outlet.Value
                                                }
                                              })
                                            : _vm._e()
                                        ]
                                      ),
                                      _c("td", [
                                        _c("h3", [
                                          _vm._v(_vm._s(outlet.Value))
                                        ]),
                                        _c("p", [
                                          _vm._v(_vm._s(outlet.Description))
                                        ])
                                      ]),
                                      _c(
                                        "td",
                                        [
                                          !_vm.blockedOptions.includes(
                                            outlet.JsonValue
                                          )
                                            ? _c(
                                                "b-button-group",
                                                [
                                                  _c(
                                                    "b-button",
                                                    {
                                                      staticClass:
                                                        "text-nowrap",
                                                      attrs: {
                                                        variant: _vm.selectedOptions.includes(
                                                          outlet.JsonValue
                                                        )
                                                          ? "success"
                                                          : "secondary"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.toggle(
                                                            outlet.JsonValue
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t("Available")
                                                        )
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "b-button",
                                                    {
                                                      staticClass:
                                                        "text-nowrap",
                                                      attrs: {
                                                        variant: !_vm.selectedOptions.includes(
                                                          outlet.JsonValue
                                                        )
                                                          ? "danger"
                                                          : "secondary"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.toggle(
                                                            outlet.JsonValue
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "Not Available"
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.blockedOptions.includes(
                                            outlet.JsonValue
                                          )
                                            ? _c("page-snippet", {
                                                attrs: {
                                                  name: "outlet_blocked"
                                                }
                                              })
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ]),
                                    outlet.JsonValue == "Beatport" &&
                                    _vm.selectedOptions.includes("Beatport")
                                      ? _c("tr", { key: "beatport" + index }, [
                                          _c("td"),
                                          _c(
                                            "td",
                                            { attrs: { colspan: "2" } },
                                            [
                                              _c("page-snippet", {
                                                attrs: {
                                                  name: "beatport-label"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ])
                                      : _vm._e()
                                  ]
                                })
                              ],
                              2
                            )
                          ])
                        : _vm._e(),
                      _vm.changes
                        ? _c("b-table-simple", { attrs: { bordered: "" } }, [
                            _c("thead", [
                              _c("tr", [
                                _c("th", { attrs: { width: "50%" } }, [
                                  _vm._v(_vm._s(_vm.$t("Portals added")))
                                ]),
                                _c("th", { attrs: { width: "50%" } }, [
                                  _vm._v(_vm._s(_vm.$t("Portals deleted")))
                                ])
                              ])
                            ]),
                            _c("tbody", [
                              _c("tr", [
                                _c("td", [
                                  _c(
                                    "ul",
                                    _vm._l(_vm.addedOptions, function(outlet) {
                                      return _c(
                                        "li",
                                        { key: outlet.JsonValue },
                                        [
                                          _vm._v(
                                            " " + _vm._s(outlet.Value) + " "
                                          )
                                        ]
                                      )
                                    }),
                                    0
                                  )
                                ]),
                                _c("td", [
                                  _c(
                                    "ul",
                                    _vm._l(_vm.removedOptions, function(
                                      outlet
                                    ) {
                                      return _c(
                                        "li",
                                        { key: outlet.JsonValue },
                                        [
                                          _vm._v(
                                            " " + _vm._s(outlet.Value) + " "
                                          )
                                        ]
                                      )
                                    }),
                                    0
                                  )
                                ])
                              ])
                            ])
                          ])
                        : _vm._e(),
                      _vm.releaseOutlets != null
                        ? _c(
                            "b-button",
                            {
                              attrs: {
                                variant: "primary",
                                disabled: !_vm.changes
                              },
                              on: {
                                click: function($event) {
                                  return _vm.$emit(
                                    "save-outlet-settings",
                                    _vm.changeJson
                                  )
                                }
                              }
                            },
                            [
                              !_vm.changes
                                ? [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("No changes have been made")
                                      )
                                    )
                                  ]
                                : _vm._e(),
                              _vm.changes
                                ? [_vm._v(_vm._s(_vm.$t("Confirm changes")))]
                                : _vm._e()
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm.releaseOutlets != null &&
                      [2, 90].includes(
                        _vm.release.ProductDetails.DeliveryStatus.StatusID
                      )
                        ? [
                            _c(
                              "b-button",
                              {
                                directives: [
                                  {
                                    name: "b-modal",
                                    rawName: "v-b-modal.takedown-modal",
                                    modifiers: { "takedown-modal": true }
                                  }
                                ],
                                staticClass: "ml-2",
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                  }
                                }
                              },
                              [_vm._v(_vm._s(_vm.$t("Full take down")))]
                            ),
                            _c(
                              "b-modal",
                              {
                                attrs: {
                                  id: "takedown-modal",
                                  title: this.$t("Full take down"),
                                  "ok-title": this.$t("Confirm"),
                                  "cancel-title": this.$t("Cancel")
                                },
                                on: {
                                  ok: function($event) {
                                    return _vm.$emit("takedown")
                                  }
                                }
                              },
                              [
                                _c("div", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.$root.$data.pageSnippets[
                                        "full-take-down"
                                      ]
                                    )
                                  }
                                })
                              ]
                            )
                          ]
                        : _vm._e(),
                      _vm.releaseOutlets != null
                        ? _c(
                            "router-link",
                            {
                              staticClass: "ml-2",
                              attrs: {
                                to: { name: "release-detail" },
                                tag: "b-button"
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("Cancel")))]
                          )
                        : _vm._e()
                    ],
                    2
                  )
                : _vm._e(),
              ["ronald@trcmusic.com"].includes(_vm.$root.$data.me.EMail) ||
              _vm.$root.$data.me.IsSuperUser
                ? _c("pre", [
                    _vm._v("        " + _vm._s(_vm.changeJson) + "\n      ")
                  ])
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }